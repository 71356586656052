var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c(
        "section",
        [
          _c(
            "b-card",
            { attrs: { "no-body": "", "no-header": "" } },
            [
              _c("b-card-body", { staticClass: "p-2" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("SUPPORT_INFORMATION")) + "\n    "
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            { attrs: { "no-body": "", "no-header": "" } },
            [
              _c("b-card-body", { staticClass: "p-2" }, [
                _vm._v(
                  "\n      \n      " +
                    _vm._s(_vm.$t("SUPPORT.FORUM_DESCRIPTION")) +
                    "\n      "
                ),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: { target: "_blank", href: "https://forum.sefos.se" },
                  },
                  [_vm._v(_vm._s(_vm.$t("SUPPORT.FORUM_BTN")))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            {
              staticClass: "mt-2",
              attrs: { "no-body": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INFORMATION")) +
                            "\n      "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                810615890
              ),
            },
            [
              _c("b-card-body", [
                _c("dl", { staticClass: "clearfix pb-2" }, [
                  _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
                  _c("dd", [_vm._v(_vm._s(_vm.user.information.name))]),
                  _c("dt", [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
                  _c("dd", [_vm._v(_vm._s(_vm.user.information.email))]),
                  _c("dt", [_vm._v("User Uuid")]),
                  _c("dd", [_vm._v(_vm._s(_vm.user.information.user_uuid))]),
                  _c("dt", [_vm._v("Organisation Uuid")]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(_vm.user.information.user_uuid.split(":")[0])
                    ),
                  ]),
                  _c("dt", [_vm._v(_vm._s(_vm.$t("LOA.NAME")))]),
                  _c("dd", [_vm._v(_vm._s(_vm.user.information.loa))]),
                  _c("dt", [_vm._v("Version")]),
                  _c("dd", [_vm._v(_vm._s(_vm.currentVersion))]),
                  _c("dt", [_vm._v("Server")]),
                  _c("dd", [_vm._v(_vm._s(_vm.serverUrl))]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }